import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Logo from "../../logo";
import { useTranslation } from "react-i18next";
import "../../../assets/css/custom.css";
import Select, { components } from "react-select";

const handleClick = (menuName) => {
    // Laporkan event ke Google Analytics
    ReactGA.event({ category: "Menu", action: "Klik", label: menuName });
};

const MobileMenu = ({ show, onClose }) => {
    // Tombol ubah Bahasa
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const idFlag = process.env.PUBLIC_URL + "/images/flag/id.png";
    const enFlag = process.env.PUBLIC_URL + "/images/flag/en-us.png";

    const languageOptions = [
        { value: "id", label: "ID", flag: idFlag },
        { value: "en", label: "EN", flag: enFlag },
    ];

    const formatOptionLabel = ({ label, flag }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
            <img src={flag} alt="" width="20" style={{ marginRight: "8px" }} />
            <span>{label}</span>
        </div>
    );

    const changeLanguage = (option) => {
        i18n.changeLanguage(option.value);
        // Laporkan event ke Google Analytics
        ReactGA.event({
            category: "Bahasa",
            action: "Ubah",
            label: option.value,
        });
    };

    // Klik

    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const menuName = target.innerText || target.textContent;
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
            ReactGA.event({
                category: "Menu Mobile",
                action: "Klik",
                label: menuName,
            });
        }
    };
    return (
        <div className={`offcanvas-modal ${show ? "show" : ""}`}>
            <div className="offcanvas-dialog">
                <div className="menu-content">
                    <div className="offcanvas-header">
                        <Logo
                            classOption="offcanvas-logo d-inline-block"
                            image={`${process.env.PUBLIC_URL}/images/logo/ecopasia-web-logo.webp`}
                        />
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        ></button>
                    </div>

                    <nav id="offcanvasNav" className="offcanvas-menu">
                        <ul>
                            <li>
                                <NavLink
                                    exact
                                    to="/"
                                    onClick={() =>
                                        handleClick("Home Menu mobile")
                                    }
                                >
                                    {t("Home Menu")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={process.env.PUBLIC_URL + "#"}
                                    onClick={() =>
                                        handleClick("Menu Tentang Mobile")
                                    }
                                >
                                    {t("Tentang Menu")}
                                </NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            exact
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/about"
                                            }
                                            onClick={() =>
                                                handleClick(
                                                    "Sub Menu Tentang Mobile"
                                                )
                                            }
                                        >
                                            {t("Sub menu tentang")}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            exact
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/gallery"
                                            }
                                            onClick={() =>
                                                handleClick(
                                                    "Sub Menu Gallery Mobile"
                                                )
                                            }
                                        >
                                            {t("Galeri Menu")}
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={process.env.PUBLIC_URL + "/service"}
                                    onClick={() =>
                                        handleClick("Menu Layanan Mobile")
                                    }
                                >
                                    {t("Layanan Menu")}
                                </NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            exact
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service-details/1"
                                            }
                                            onClick={() =>
                                                handleClick(
                                                    "Sub Menu Layanan Jemput Mobile"
                                                )
                                            }
                                        >
                                            {t("Jemput Menu")}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            exact
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service-details/2"
                                            }
                                            onClick={() =>
                                                handleClick(
                                                    "Sub Menu Layanan Antar Mobile"
                                                )
                                            }
                                        >
                                            {t("Antar Menu")}
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {/* <li>
                                <NavLink to="/">Solusi</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            to={process.env.PUBLIC_URL + "/faq"}
                                        >
                                            Untuk Semua
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL + "/team"
                                            }
                                        >
                                            Bisnis
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service"
                                            }
                                        >
                                            Merek & Perusahaan
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service"
                                            }
                                        >
                                            Yayasan
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> */}
                            <li>
                                <NavLink
                                    exact
                                    to={process.env.PUBLIC_URL + "/blog"}
                                    onClick={() =>
                                        handleClick("Menu Berita Mobile")
                                    }
                                >
                                    {t("Berita Menu")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={process.env.PUBLIC_URL + "/contact"}
                                    onClick={() =>
                                        handleClick("Menu Kontak Mobile")
                                    }
                                >
                                    {t("Kontak Menu")}
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <Select
                        className="select-language"
                        options={languageOptions}
                        formatOptionLabel={formatOptionLabel}
                        isSearchable={false}
                        onChange={changeLanguage}
                        value={languageOptions.find(
                            (option) => option.value === i18n.language
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
