import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TeamContainer from "../containers/global/team";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Ecopasia – Layanan" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={t("Our Services")}
                        excerpt={t(
                            "ECOPASIA mengurangi dan daur ulang sampah plastik dan kayu di Jawa Timur. Kami juga mengajarkan tentang lingkungan dan komunitas berkelanjutan. Kami ingin kurangi sampah di darat dan air, dan buat lapangan kerja. Kami pakai inovasi dan pendidikan untuk ubah masa depan jadi lebih hijau."
                        )}
                        image="./images/service/banner-atas.webp"
                    />
                    <ServiceListContainer />
                    {/* <TestimonialReverse />
                    <FunFactContainer classOption="mt-0 mt-lg-0" />
                    <TeamContainer classOption="null" /> */}
                    <BrandContainer />
                    <NewsletterArea />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServicePage;
