import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import IconBox from "../../../components/icon-box";
import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import { useTranslation } from "react-i18next";

const IconBoxContainer = ({ classOption }) => {
    const { t } = useTranslation();
    return (
        <div className={`feature-section position-relative ${classOption}`}>
            <img
                className="path-img"
                src={`${process.env.PUBLIC_URL}/images/feature/shape.webp`}
                alt="images_not_found"
            />
            <div className="container custom-container">
                <div className="row g-0 align-items-center">
                    <div className="col-xl-6 col-lg-8 mx-auto mb-3 text-center">
                        <SectionTitle
                            classOption="title-section"
                            subTitle={t("Misi Kami")}
                            title={t(
                                "Pengurangan Sampah <span class='text-primary'>Plastik </span> dan <span class='text-primary'>Kayu...</span>"
                            )}
                            excerptClassOption="mb-10"
                            excerpt={t(
                                "Kami bertujuan untuk mengurangi jumlah sampah plastik dan kayu di darat dan di air melalui program daur ulang kami."
                            )}
                        />
                        <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark"
                            text={t("Semua tentang kami")}
                            path="/about"
                        />
                    </div>

                    <div className="col-12">
                        <div id="grid" className="grid row mb-n7">
                            {HomeData[2].iconBox &&
                                HomeData[2].iconBox.map((single, key) => {
                                    // Salin objek 'single' dan ganti properti 'title' dengan versi yang diterjemahkan
                                    const translatedSingle = {
                                        ...single,
                                        title: t(single.title),
                                        excerpt: t(single.excerpt),
                                    };
                                    return (
                                        <IconBox
                                            key={key}
                                            data={translatedSingle}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

IconBoxContainer.propTypes = {
    classOption: PropTypes.string,
};

IconBoxContainer.defaultProps = {
    classOption: "section-pb",
};

export default IconBoxContainer;
