import ReactGA from "react-ga4";
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const photos = [
    {
        src: "/images/gallery/1.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/2.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/3.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/4.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/5.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/6.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/7.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/8.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/9.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/10.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/11.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/12.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/13.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/14.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/15.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/16.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/17.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/18.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/19.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/20.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/21.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/22.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/23.jpeg",
        width: 450,
        height: 800,
    },
    {
        src: "/images/gallery/24.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/25.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/26.jpeg",
        width: 640,
        height: 360,
    },
    {
        src: "/images/gallery/27.jpeg",
        width: 450,
        height: 800,
    },
    // tambahkan objek foto lainnya di sini
];

const handleClick = (imageName) => {
    // Laporkan event ke Google Analytics
    ReactGA.event({
        category: "Galeri Foto",
        action: "Klik",
        label: imageName,
    });
};

const PhotoGallery = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
        handleClick(photo.src);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <section className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <Gallery photos={photos} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photos.map((x) => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title,
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </div>
        </section>
    );
};

export default PhotoGallery;
