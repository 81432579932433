// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    "Tentang Btn": "About Us",
                    "Mulai Sekarang": "Book A Meeting",
                    "Jaga lingkungan dengan Mendaur ulang sampah kita":
                        "Protect the environment by recycling our waste",
                    "Home Menu": "Home",
                    "Tentang Menu": "About",
                    "Sub menu tentang": "About Us",
                    "Layanan Menu": "Service",
                    "Galeri Menu": "Gallery",
                    "Jemput Menu": "Pick Up",
                    "Antar Menu": "Drop Off",
                    "Berita Menu": "News",
                    "Kontak Menu": "Contact",
                    "Misi Kami": "Our Mission",
                    "Pengurangan Sampah <span class='text-primary'>Plastik </span> dan <span class='text-primary'>Kayu...</span>":
                        "Reducing <span class='text-primary'>Plastic</span> and <span class='text-primary'>Wood Waste...</span>",
                    "Kami bertujuan untuk mengurangi jumlah sampah plastik dan kayu di darat dan di air melalui program daur ulang kami.":
                        "We aim to reduce the amount of plastic and wood waste on land and in the water through our recycling program.",
                    "Semua tentang kami": "All about Us",
                    "SIAPA KAMI?": "WHO ARE WE?",
                    "Sang pelopor":
                        "The<span class='text-primary'> Pioneer</span><br className='d-none d-xl-block' /> for real change.",
                    "Sustainable Communities": "Sustainable Communities",
                    "Edukasi Lingkungan": "Environmental Education",
                    "Inovasi Berkelanjutan": "Sustainable Innovation",
                    "Kami berusaha untuk membangun komunitas yang berkelanjutan dengan mendorong praktek daur ulang dan pengurangan sampah.":
                        "We strive to build sustainable communities by encouraging recycling and waste reduction practices.",
                    "Kami berdedikasi untuk mendidik masyarakat tentang pentingnya pengelolaan sampah yang bertanggung jawab dan ramah lingkungan.":
                        "We are dedicated to educating the public about the importance of responsible and environmentally friendly waste management.",
                    "Kami berkomitmen untuk terus berkembang dan berinovasi dalam upaya kami untuk mencapai tujuan kami dengan cara yang lebih ramah lingkungan.":
                        "We are committed to continuing to develop and innovate in our efforts to achieve our goals in a more environmentally friendly way.",
                    Berdiri: "Since",
                    "ECOPASIA berkomitmen untuk menjadi pemimpin dalam pengurangan dan daur ulang plastik dan kayu di Jawa Timur.":
                        "ECOPASIA is committed to being a leader in reducing and recycling plastic and wood in East Java.",
                    "Kami percaya bahwa melalui inovasi dan pendekatan yang ramah lingkungan, kita dapat menciptakan perubahan nyata dan berkelanjutan.":
                        "We believe that through innovation and an environmentally friendly approach, we can create real and sustainable change.",
                    "Btn Selengkapnya": "Learn more",
                    "Layanan Kami": "Our service",
                    "Lihat <span class='text-primary'>Layanan</span> kami":
                        "View Our <span class='text-primary'>Featured Services</span>",
                    "Gerakan daur ulang Ecopasia untuk seluruh masyarakat.":
                        "Ecopasia recycling movement for the whole community.",
                    "Layanan Jemput": "Pick Up",
                    "Kontribusi Anda untuk lingkungan, minta sampah daur ulang ke Ecopasia yuk!":
                        "Your contribution to the environment, let's ask Ecopasia for recycled waste!",

                    "<p class='text-primary fw-semi-bold mb-6'>Kontribusi Anda untuk lingkungan: unggah foto sampah daur ulang ke Ecopasia! </p>":
                        "<p class='text-primary fw-semi-bold mb-6'>Your contribution to the environment: upload photos of recycling waste to Ecopasia!</p>",
                    "<p class='mb-8'>Jangan buang sampah daur ulang Anda begitu saja! Jadilah bagian dari gerakan lingkungan dengan mengunggah foto sampah daur ulang Anda ke aplikasi Ecopasia. Tim Ecopasia akan segera menjemput sampah Anda dan memberikan imbalan yang pantas untuk usaha Anda dalam menjaga lingkungan tetap bersih dan sehat. </p>":
                        "<p class='mb-8'>Don't just throw away your recyclable waste! Be part of the environmental movement by uploading a photo of your recyclable waste to the Ecopasia application. The Ecopasia team will immediately pick up your waste and provide appropriate compensation for your efforts in keeping the environment clean and healthy.</p>",
                    "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i> Kumpulkan sampah plastikmu</p> <p><i class='fa fa-angle-double-right'></i>Hubungi kami untuk penjemputan sampah</p><p><i class='fa fa-angle-double-right'></i>Tim kami akan mengambil sampah ke lokasi mu</p><p><i class='fa fa-angle-double-right'></i>Kami akan membayarmu sebagai apresiasi telah menjaga lingkungan</p></div>":
                        "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i>Collect your plastic waste</p><p><i class='fa fa-angle-double-right'></i>Contact us for trash pickup</p><p><i class='fa fa-angle-double-right'></i>Our team will take the trash to your location</p><p><i class='fa fa-angle-double-right'></i>We will pay you as appreciation for protecting the environment</p></div>",
                    "<div class='row sub-section-mb70'><div class='col-xl-6 col-md-5 order-last order-lg-first text-center'><img class='mr-n2 mt-7 mt-md-0' src='/images/service/jemput4.webp' alt='service' /></div><div class='col-xl-5 col-md-6 offset-xl-1'><div class='sub-section-title'><h3 class='title'>Jemput sampah</h3><p>Anda tidak perlu khawatir lagi tentang cara membuang sampah daur ulang Anda. Cukup unggah foto sampah daur ulang Anda ke aplikasi Ecopasia, dan kolektor Ecopasia terdekat akan segera menjemput sampah Anda, menimbang, dan memberikan imbalan yang pantas. Dengan fitur ini, Anda dapat menjaga lingkungan tetap bersih dan sehat dengan mudah dan efisien. </p></div></div></div>":
                        "<div class='row sub-section-mb70'><div class='col-xl-6 col-md-5 order-last order-lg-first text-center'><img class='mr-n2 mt-7 mt-md-0' src='/images/service/jemput4.webp' alt='service'></div><div class='col-xl-5 col-md-6 offset-xl-1'><div class='sub-section-title'><h3 class='title'>Pick up trash</h3><p>You don't have to worry anymore about how to dispose of your recyclables. Just upload a photo of your recyclable waste to the Ecopasia app, and the nearest Ecopasia collector will immediately pick up your waste, weigh it, and give you the appropriate reward. With this feature, you can keep your environment clean and healthy easily and efficiently.</p></div></div></div>",
                    "Layanan Diantar": "Drop Off",
                    "Bawa sampahmu ke pusat daur ulang terdekat dan buat perbedaan untuk lingkungan!":
                        "Take your trash to the nearest recycling center and make a difference for the environment!",
                    "<p class='text-primary fw-semi-bold mb-6'>Bawa sampahmu ke pusat daur ulang terdekat <br class='d-none d-xl-block' />buat perbedaan untuk lingkungan! </p>":
                        "<p class='text-primary fw-semi-bold mb-6'>Don't forget to always bring and sort your rubbish properly, then take it to the nearest recycling center around you. By doing this, you not only keep the surrounding environment clean, but also contribute to efforts to preserve nature and reduce the negative impact on our earth.</p>",
                    "<p class='mb-8'>Ayo, jangan biarkan sampahmu menumpuk! Bawa langsung ke pusat daur ulang terdekat dan ubah sampahmu menjadi hal yang bermanfaat. Bahkan satu botol plastik pun bisa membuat perbedaan besar dalam menjaga lingkungan kita tetap sehat dan lestari. </p>":
                        "<p class='mb-8'>Come on, don't let your trash pile up! With awareness and simple actions, take your rubbish directly to the nearest recycling center. There, every unused object can undergo transformation into something useful. In fact, just one plastic bottle can have a big impact on preserving our environment.</p>",
                    "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i> Kumpulin sampah plastik dirumahmu</p> <p><i class='fa fa-angle-double-right'></i>Simpan ditempat yang aman dengan rapi</p><p><i class='fa fa-angle-double-right'></i>Kalau sudah banyak, antarkan sampah plastikmu ke tempat mitra Ecopasia</p><p><i class='fa fa-angle-double-right'></i>Ecopasia akan membayarmu untuk apresiasi telah menjaga lingkungan</p></div>":
                        "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i> Start by collecting plastic waste in your house carefully.</p> <p><i class='fa fa-angle-double-right'></i>Arrange the storage area neatly and safely.</p><p><i class='fa fa-angle-double-right'></i>When you have collected a sufficient amount, deliver your plastic waste to Ecopasia partner locations.</p><p><i class='fa fa-angle-double-right'></i>They will reward you for your dedication in keeping the environment clean.</p></div>",
                    "<div class='row sub-section-mb mt-7 mt-lg-0'><div class='col-xl-6 col-md-6'><div class='sub-section-title'><h3 class='title'>Kumpulkan</h3><p>Jangan biarkan sampahmu menumpuk di rumah, bawa saja ke pusat daur ulang yang terdekat. Di sana, kamu bisa mengubah barang-barang bekas yang tidak terpakai menjadi bahan-bahan baru yang berguna. Tidak masalah seberapa kecil ukuran sampahmu, bahkan satu botol plastik pun bisa didaur ulang menjadi produk yang bermanfaat. Dengan melakukan daur ulang, kamu bisa membantu menjaga lingkungan dan menghemat sumber daya alam. </p></div></div><div class='col-xl-5 col-md-6 text-center mt-7 mt-md-0 offset-xl-1'><img class='mt-xl-n3 ml-n2' src='/images/service/diantar.png' alt='service' /></div></div>":
                        "<div class='row sub-section-mb mt-7 mt-lg-0'><div class='col-xl-6 col-md-6'><div class='sub-section-title'><h3 class='title'>What is the impact?</h3><p>With each of these steps, you not only manage your waste wisely, but also play a role in saving the environment. Always remember, no size is too small to make a difference. Even one plastic bottle can be the start of a healthier and more sustainable environment.</p></div></div><div class='col-xl-5 col-md-6 text-center mt-7 mt-md-0 offset-xl-1'><img class='mt-xl-n3 ml-n2' src='/images/service/diantar.png' alt='service' /></div></div>",
                    "Nama Lengkap*": "Your Name*",
                    "Kenalan dulu yuk....":
                        "Oops... Your name hasn't been filled in yet.",
                    "Email Kontak*": "Your Email",
                    "Email nya belum nih...":
                        "Oops... Email has not been filled in.",
                    "Tuliskan pesan disini*": "Write message here*",
                    "Eitss... belum diisi nih": "Please write your message",
                    "Kirim Pesan": "Send Messages",
                    "Well Done!": "Well Done!",
                    "Ikut Kontribusi?": "Interested in Contributing?",
                    "Hubungi kami untuk meminta penjemputan sampah ke tempat kamu atau minta proposal untuk bekerjasama wujudkan Indonesia hijau.":
                        "Contact us to request trash pickup to your place or ask for a proposal to work together to make Indonesia green.",
                    "Alamat Kantor": "Office address",
                    "H2 Kontak": "Contact Us",
                    "Mari berkolaborasi dalam menjaga lingkungan <br /> dan jangan ragu untuk menghubungi kami":
                        "Let's collaborate in protecting the environment <br /> and don't hesitate to contact us",
                    "Berlangganan <span class='text-primary'>Newsletter</span> &amp; <br class='d-none d-xl-block' /> dapatkan pembaruan...":
                        "Subscribe <span class='text-primary'>Newsletter</span> &amp; <br class='d-none d-xl-block' /> get latest update...",
                    "Masukin email kamu ya...": "Enter email here...",
                    Berlangganan: "Subscribe",
                    "Lagi OTW, tunggu sebentar yaa...": "Sending...",
                    "Our Services": "Our Services",
                    "ECOPASIA mengurangi dan daur ulang sampah plastik dan kayu di Jawa Timur. Kami juga mengajarkan tentang lingkungan dan komunitas berkelanjutan. Kami ingin kurangi sampah di darat dan air, dan buat lapangan kerja. Kami pakai inovasi dan pendidikan untuk ubah masa depan jadi lebih hijau.":
                        "ECOPASIA reduces and recycles plastic and wood waste in East Java. We also teach about the environment and sustainable communities. We want to reduce waste on land and water, and create jobs. We use innovation and education to change the future to be greener. ",
                    "Blog Post": "Latest News",
                    "Berita terbaru dari": "Latest news from",
                    "ECOPASIA berdedikasi untuk mengurangi dan mendaur ulang sampah plastik dan kayu di Jawa Timur, mempromosikan edukasi lingkungan, dan mendorong pembentukan komunitas berkelanjutan.":
                        "ECOPASIA is dedicated to reducing and recycling plastic and wood waste in East Java, promoting environmental education, and encouraging the formation of sustainable communities.",
                    "VISI KAMI": "OUR VISION",
                    "Komitmen <span class='text-primary'>Ecopasia</span>":
                        "Ecopasia <span class='text-primary'>Commitment</span>",
                    "ECOPASIA berkomitmen untuk menjadi pemimpin dalam pengurangan dan daur ulang plastik dan kayu di Jawa Timur. Kami percaya bahwa melalui inovasi dan pendekatan yang ramah lingkungan, kita dapat menciptakan perubahan nyata dan berkelanjutan.":
                        "ECOPASIA is committed to being a leader in reducing and recycling plastic and wood in East Java. We believe that through innovation and an environmentally friendly approach, we can create real and sustainable change.",
                    "Teks Footer":
                        "Let's keep the environment clean together, by reducing and recycling our waste, into something more valuable and useful.",
                    "Link Cepat": "Quick Link",
                    "Berita Terbaru": "Latest Article",
                    "ARTIKEL BERITA": "LATEST ArTICLE",
                    "Berita ter-apdet,<span class='text-primary'> Buat kamu!</span>.":
                        "Latest<span class='text-primary'>Posts, </span>for you.",
                    "Baca artikel terbaru dari CNBN <br /> temukan lebih lanjut untuk #HijauIndonesiaku":
                        "Read the latest articles <br /> find out more about #HijauIndonesiaku",
                    "Info Kontak Footer": "Contact Info",
                    "Collaborated with": "Collaborated with",
                    By: "By",
                    Copyright: "Copyright",
                    Galeri: "Gallery",
                    "Deskripsi Galeri": "Adventure Through Our Lens",
                },
            },
            id: {
                translation: {
                    "Tentang Btn": "Tentang Kami",
                    "Mulai Sekarang": "Mulai Sekarang",
                    "Jaga lingkungan dengan Mendaur ulang sampah kita":
                        "Jaga lingkungan dengan Mendaur ulang sampah kita",
                    "Home Menu": "Beranda",
                    "Tentang Menu": "Tentang",
                    "Sub menu tentang": "Tentang Kami",
                    "Layanan Menu": "Layanan",
                    "Galeri Menu": "Galeri",
                    "Jemput Menu": "Jemput",
                    "Antar Menu": "Antar",
                    "Berita Menu": "Berita",
                    "Kontak Menu": "Kontak",
                    "Misi Kami": "Misi Kami",
                    "Pengurangan Sampah <span class='text-primary'>Plastik </span> dan <span class='text-primary'>Kayu...</span>":
                        "Pengurangan Sampah <span class='text-primary'>Plastik </span> dan <span class='text-primary'>Kayu...</span>",
                    "Kami bertujuan untuk mengurangi jumlah sampah plastik dan kayu di darat dan di air melalui program daur ulang kami.":
                        "Kami bertujuan untuk mengurangi jumlah sampah plastik dan kayu di darat dan di air melalui program daur ulang kami.",
                    "Semua tentang kami": "Semua tentang kami",
                    "SIAPA KAMI?": "SIAPA KAMI?",
                    "Sang pelopor":
                        "Sang <span class='text-primary'> Pelopor </span><br className='d-none d-xl-block' /> untuk perubahan nyata.",
                    "Sustainable Communities": "Komunitas Berkelanjutan",
                    "Edukasi Lingkungan": "Edukasi Lingkungan",
                    "Inovasi Berkelanjutan": "Inovasi Berkelanjutan",
                    "Kami berusaha untuk membangun komunitas yang berkelanjutan dengan mendorong praktek daur ulang dan pengurangan sampah.":
                        "Kami berusaha untuk membangun komunitas yang berkelanjutan dengan mendorong praktek daur ulang dan pengurangan sampah.",
                    "Kami berdedikasi untuk mendidik masyarakat tentang pentingnya pengelolaan sampah yang bertanggung jawab dan ramah lingkungan.":
                        "Kami berdedikasi untuk mendidik masyarakat tentang pentingnya pengelolaan sampah yang bertanggung jawab dan ramah lingkungan.",
                    "Kami berkomitmen untuk terus berkembang dan berinovasi dalam upaya kami untuk mencapai tujuan kami dengan cara yang lebih ramah lingkungan.":
                        "Kami berkomitmen untuk terus berkembang dan berinovasi dalam upaya kami untuk mencapai tujuan kami dengan cara yang lebih ramah lingkungan.",
                    Berdiri: "Berdiri",
                    "ECOPASIA berkomitmen untuk menjadi pemimpin dalam pengurangan dan daur ulang plastik dan kayu di Jawa Timur.":
                        "ECOPASIA berkomitmen untuk menjadi pemimpin dalam pengurangan dan daur ulang plastik dan kayu di Jawa Timur.",
                    "Kami percaya bahwa melalui inovasi dan pendekatan yang ramah lingkungan, kita dapat menciptakan perubahan nyata dan berkelanjutan.":
                        "Kami percaya bahwa melalui inovasi dan pendekatan yang ramah lingkungan, kita dapat menciptakan perubahan nyata dan berkelanjutan.",
                    "Btn Selengkapnya": "Selengkapnya",
                    "Layanan Kami": "Layanan Kami",
                    "Lihat <span class='text-primary'>Layanan</span> kami":
                        "Lihat <span class='text-primary'>Layanan</span> kami",
                    "Gerakan daur ulang Ecopasia untuk seluruh masyarakat.":
                        "Gerakan daur ulang Ecopasia untuk seluruh masyarakat.",
                    "Layanan Jemput": "Di Jemput",
                    "Kontribusi Anda untuk lingkungan, minta sampah daur ulang ke Ecopasia yuk!":
                        "Kontribusi Anda untuk lingkungan, minta sampah daur ulang ke Ecopasia yuk!",
                    "<p class='text-primary fw-semi-bold mb-6'>Kontribusi Anda untuk lingkungan: unggah foto sampah daur ulang ke Ecopasia! </p>":
                        "<p class='text-primary fw-semi-bold mb-6'>Kontribusi Anda untuk lingkungan: unggah foto sampah daur ulang ke Ecopasia! </p>",
                    "<p class='mb-8'>Jangan buang sampah daur ulang Anda begitu saja! Jadilah bagian dari gerakan lingkungan dengan mengunggah foto sampah daur ulang Anda ke aplikasi Ecopasia. Tim Ecopasia akan segera menjemput sampah Anda dan memberikan imbalan yang pantas untuk usaha Anda dalam menjaga lingkungan tetap bersih dan sehat. </p>":
                        "<p class='mb-8'>Jangan buang sampah daur ulang Anda begitu saja! Jadilah bagian dari gerakan lingkungan dengan mengunggah foto sampah daur ulang Anda ke aplikasi Ecopasia. Tim Ecopasia akan segera menjemput sampah Anda dan memberikan imbalan yang pantas untuk usaha Anda dalam menjaga lingkungan tetap bersih dan sehat. </p>",
                    "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i> Kumpulkan sampah plastikmu</p> <p><i class='fa fa-angle-double-right'></i>Hubungi kami untuk penjemputan sampah</p><p><i class='fa fa-angle-double-right'></i>Tim kami akan mengambil sampah ke lokasi mu</p><p><i class='fa fa-angle-double-right'></i>Kami akan membayarmu sebagai apresiasi telah menjaga lingkungan</p></div>":
                        "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i> Kumpulkan sampah plastikmu</p> <p><i class='fa fa-angle-double-right'></i>Hubungi kami untuk penjemputan sampah</p><p><i class='fa fa-angle-double-right'></i>Tim kami akan mengambil sampah ke lokasi mu</p><p><i class='fa fa-angle-double-right'></i>Kami akan membayarmu sebagai apresiasi telah menjaga lingkungan</p></div>",
                    "<div class='row sub-section-mb70'><div class='col-xl-6 col-md-5 order-last order-lg-first text-center'><img class='mr-n2 mt-7 mt-md-0' src='/images/service/jemput4.webp' alt='service' /></div><div class='col-xl-5 col-md-6 offset-xl-1'><div class='sub-section-title'><h3 class='title'>Jemput sampah</h3><p>Anda tidak perlu khawatir lagi tentang cara membuang sampah daur ulang Anda. Cukup unggah foto sampah daur ulang Anda ke aplikasi Ecopasia, dan kolektor Ecopasia terdekat akan segera menjemput sampah Anda, menimbang, dan memberikan imbalan yang pantas. Dengan fitur ini, Anda dapat menjaga lingkungan tetap bersih dan sehat dengan mudah dan efisien. </p></div></div></div>":
                        "<div class='row sub-section-mb70'><div class='col-xl-6 col-md-5 order-last order-lg-first text-center'><img class='mr-n2 mt-7 mt-md-0' src='/images/service/jemput4.webp' alt='service' /></div><div class='col-xl-5 col-md-6 offset-xl-1'><div class='sub-section-title'><h3 class='title'>Jemput sampah</h3><p>Anda tidak perlu khawatir lagi tentang cara membuang sampah daur ulang Anda. Cukup unggah foto sampah daur ulang Anda ke aplikasi Ecopasia, dan kolektor Ecopasia terdekat akan segera menjemput sampah Anda, menimbang, dan memberikan imbalan yang pantas. Dengan fitur ini, Anda dapat menjaga lingkungan tetap bersih dan sehat dengan mudah dan efisien. </p></div></div></div>",
                    "Layanan Diantar": "Diantar",
                    "Bawa sampahmu ke pusat daur ulang terdekat dan buat perbedaan untuk lingkungan!":
                        "Bawa sampahmu ke pusat daur ulang terdekat dan buat perbedaan untuk lingkungan!",
                    "<p class='text-primary fw-semi-bold mb-6'>Bawa sampahmu ke pusat daur ulang terdekat <br class='d-none d-xl-block' />buat perbedaan untuk lingkungan! </p>":
                        "<p class='text-primary fw-semi-bold mb-6'>Jangan lupa untuk selalu membawa dan memilah sampah-sampahmu dengan baik, lalu antarlah ke pusat daur ulang terdekat di sekitarmu. Dengan melakukan hal ini, kamu tidak hanya menjaga kebersihan lingkungan sekitar, tetapi juga turut berkontribusi dalam upaya pelestarian alam dan mengurangi dampak negatif terhadap bumi kita.</p>",
                    "<p class='mb-8'>Ayo, jangan biarkan sampahmu menumpuk! Bawa langsung ke pusat daur ulang terdekat dan ubah sampahmu menjadi hal yang bermanfaat. Bahkan satu botol plastik pun bisa membuat perbedaan besar dalam menjaga lingkungan kita tetap sehat dan lestari. </p>":
                        "<p class='mb-8'>Ayo, jangan biarkan sampahmu menumpuk! Dengan kesadaran dan tindakan sederhana, bawa langsung sampah-sampahmu ke pusat daur ulang terdekat. Di sana, setiap benda tak terpakai bisa mengalami transformasi menjadi sesuatu yang bermanfaat. Bahkan, hanya satu botol plastik pun bisa memiliki dampak besar dalam menjaga kelestarian lingkungan kita.</p>",
                    "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i> Kumpulin sampah plastik dirumahmu</p> <p><i class='fa fa-angle-double-right'></i>Simpan ditempat yang aman dengan rapi</p><p><i class='fa fa-angle-double-right'></i>Kalau sudah banyak, antarkan sampah plastikmu ke tempat mitra Ecopasia</p><p><i class='fa fa-angle-double-right'></i>Ecopasia akan membayarmu untuk apresiasi telah menjaga lingkungan</p></div>":
                        "<div class='single-service-list'><p><i class='fa fa-angle-double-right'></i> Mulailah dengan mengumpulkan sampah plastik di rumahmu dengan teliti.</p> <p><i class='fa fa-angle-double-right'></i>Aturlah tempat penyimpanannya dengan rapi dan aman.</p><p><i class='fa fa-angle-double-right'></i>Ketika sudah terkumpul sejumlah yang cukup, antarkanlah sampah plastikmu ke tempat mitra Ecopasia.</p><p><i class='fa fa-angle-double-right'></i>Mereka akan memberimu penghargaan atas dedikasimu dalam menjaga kebersihan lingkungan.</p></div>",
                    "<div class='row sub-section-mb mt-7 mt-lg-0'><div class='col-xl-6 col-md-6'><div class='sub-section-title'><h3 class='title'>Kumpulkan</h3><p>Jangan biarkan sampahmu menumpuk di rumah, bawa saja ke pusat daur ulang yang terdekat. Di sana, kamu bisa mengubah barang-barang bekas yang tidak terpakai menjadi bahan-bahan baru yang berguna. Tidak masalah seberapa kecil ukuran sampahmu, bahkan satu botol plastik pun bisa didaur ulang menjadi produk yang bermanfaat. Dengan melakukan daur ulang, kamu bisa membantu menjaga lingkungan dan menghemat sumber daya alam. </p></div></div><div class='col-xl-5 col-md-6 text-center mt-7 mt-md-0 offset-xl-1'><img class='mt-xl-n3 ml-n2' src='/images/service/diantar.png' alt='service' /></div></div>":
                        "<div class='row sub-section-mb mt-7 mt-lg-0'><div class='col-xl-6 col-md-6'><div class='sub-section-title'><h3 class='title'>Apa Dampaknya?</h3><p>Dengan setiap langkah ini, kamu bukan hanya mengelola sampahmu dengan bijak, tetapi juga ikut berperan dalam menyelamatkan lingkungan. Selalu ingat, tidak ada ukuran terlalu kecil untuk melakukan perubahan. Bahkan satu botol plastik pun bisa menjadi awal dari lingkungan yang lebih sehat dan lestari.</p></div></div><div class='col-xl-5 col-md-6 text-center mt-7 mt-md-0 offset-xl-1'><img class='mt-xl-n3 ml-n2' src='/images/service/diantar.png' alt='service' /></div></div>",
                    "Nama Lengkap*": "Nama Lengkap*",
                    "Kenalan dulu yuk....": "Kenalan dulu yuk....",
                    "Email Kontak*": "Email*",
                    "Email nya belum nih...": "Email nya belum nih...",
                    "email kamu nggak valid nih...":
                        "email kamu nggak valid nih...",
                    "Tuliskan pesan disini*": "Tuliskan pesan disini*",
                    "Eitss... belum diisi nih": "Eitss... belum diisi nih",
                    "Kirim Pesan": "Kirim",
                    "Well Done!": "Yeay Berhasil!",
                    "Ikut Kontribusi?": "Tertarik Berkontribusi?",
                    "Hubungi kami untuk meminta penjemputan sampah ke tempat kamu atau minta proposal untuk bekerjasama wujudkan Indonesia hijau.":
                        "Hubungi kami untuk meminta penjemputan sampah ke tempat kamu atau minta proposal untuk bekerjasama wujudkan Indonesia hijau.",
                    "Alamat Kantor": "Alamat Kantor",
                    "H2 Kontak": "Kontak Kami",
                    "Mari berkolaborasi dalam menjaga lingkungan <br /> dan jangan ragu untuk menghubungi kami":
                        "Mari berkolaborasi dalam menjaga lingkungan <br /> dan jangan ragu untuk menghubungi kami",
                    "Berlangganan <span class='text-primary'>Newsletter</span> &amp; <br class='d-none d-xl-block' /> dapatkan pembaruan...":
                        "Berlangganan <span class='text-primary'>Newsletter</span> &amp; <br class='d-none d-xl-block' /> dapatkan pembaruan...",
                    "Masukin email kamu ya...": "Masukin email kamu ya...",
                    Berlangganan: "Berlangganan",
                    "Lagi OTW, tunggu sebentar yaa...":
                        "Lagi OTW, tunggu sebentar yaa...",
                    "Our Services": "Layanan Kami",
                    "ECOPASIA mengurangi dan daur ulang sampah plastik dan kayu di Jawa Timur. Kami juga mengajarkan tentang lingkungan dan komunitas berkelanjutan. Kami ingin kurangi sampah di darat dan air, dan buat lapangan kerja. Kami pakai inovasi dan pendidikan untuk ubah masa depan jadi lebih hijau.":
                        "ECOPASIA mengurangi dan daur ulang sampah plastik dan kayu di Jawa Timur. Kami juga mengajarkan tentang lingkungan dan komunitas berkelanjutan. Kami ingin kurangi sampah di darat dan air, dan buat lapangan kerja. Kami pakai inovasi dan pendidikan untuk ubah masa depan jadi lebih hijau.",
                    "Blog Post": "Berita Terkini",
                    "Berita terbaru dari": "Berita terbaru dari",
                    "ECOPASIA berdedikasi untuk mengurangi dan mendaur ulang sampah plastik dan kayu di Jawa Timur, mempromosikan edukasi lingkungan, dan mendorong pembentukan komunitas berkelanjutan.":
                        "ECOPASIA berdedikasi untuk mengurangi dan mendaur ulang sampah plastik dan kayu di Jawa Timur, mempromosikan edukasi lingkungan, dan mendorong pembentukan komunitas berkelanjutan.",
                    "VISI KAMI": "VISI KAMI",
                    "Komitmen <span class='text-primary'>Ecopasia</span>":
                        "Komitmen <span class='text-primary'>Ecopasia</span>",
                    "ECOPASIA berkomitmen untuk menjadi pemimpin dalam pengurangan dan daur ulang plastik dan kayu di Jawa Timur. Kami percaya bahwa melalui inovasi dan pendekatan yang ramah lingkungan, kita dapat menciptakan perubahan nyata dan berkelanjutan.":
                        "ECOPASIA berkomitmen untuk menjadi pemimpin dalam pengurangan dan daur ulang plastik dan kayu di Jawa Timur. Kami percaya bahwa melalui inovasi dan pendekatan yang ramah lingkungan, kita dapat menciptakan perubahan nyata dan berkelanjutan.",
                    "Teks Footer":
                        "Mari sama-sama jaga kebersihan lingkungan, dengan mengurangi dan mendaur ulang sampah kita, menjadi sesuatu yang lebih berharga dan bermanfaat.",
                    "Link Cepat": "Link Cepat",
                    "Berita Terbaru": "Berita Terbaru",
                    "ARTIKEL BERITA": "ARTIKEL BERITA",
                    "Berita ter-apdet,<span class='text-primary'> Buat kamu!</span>.":
                        "Berita ter-apdet,<span class='text-primary'> Buat kamu!</span>.",
                    "Baca artikel terbaru dari CNBN <br /> temukan lebih lanjut untuk #HijauIndonesiaku":
                        "Baca artikel terbaru<br /> temukan lebih lanjut untuk #HijauIndonesiaku",
                    "Info Kontak Footer": "Info Kontak",
                    "Collaborated with": "Dikolaborasikan Dengan",
                    By: "Oleh",
                    Copyright: "Hak Cipta Dilindungi Undang-undang",
                    Galeri: "Galeri",
                    "Deskripsi Galeri": "Petualangan Melalui Lensa Kami",
                },
            },
        },
        lng: "id", // Bahasa default diubah menjadi 'id'
        fallbackLng: "en",

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
