import React from "react";
import Newsletter from "../../../components/newsletter";
import SectionTitle from "../../../components/section-title";
import { useTranslation } from "react-i18next";

const NewsletterArea = () => {
    const { t } = useTranslation();
    return (
        <div className="news-letter-section section-pb">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-xl-6 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 text-center"
                            subTitle="NEWSLETTER"
                            title={t(
                                "Berlangganan <span class='text-primary'>Newsletter</span> &amp; <br class='d-none d-xl-block' /> dapatkan pembaruan..."
                            )}
                        />
                    </div>
                    <div className="col-xl-8 col-lg-10 mx-auto">
                        <Newsletter mailchimpUrl="//ecopasia.us8.list-manage.com/subscribe/post?u=8da5a41c240f9b9f330e36a52&amp;id=718635bf6b&amp;f_id=009a76e0f0" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsletterArea;
