import ReactGA from "react-ga4";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../assets/css/custom.css";
import Select, { components } from "react-select";

const handleClick = (menuName) => {
    // Laporkan event ke Google Analytics
    ReactGA.event({ category: "Menu", action: "Klik", label: menuName });
};

const MainMenu = () => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const idFlag = process.env.PUBLIC_URL + "/images/flag/id.png";
    const enFlag = process.env.PUBLIC_URL + "/images/flag/en-us.png";

    const languageOptions = [
        { value: "id", label: "ID", flag: idFlag },
        { value: "en", label: "EN", flag: enFlag },
    ];

    const formatOptionLabel = ({ label, flag }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
            <img src={flag} alt="" width="20" style={{ marginRight: "8px" }} />
            <span>{label}</span>
        </div>
    );

    const changeLanguage = (option) => {
        i18n.changeLanguage(option.value);
        // Laporkan event ke Google Analytics
        ReactGA.event({
            category: "Bahasa",
            action: "Ubah",
            label: option.value,
        });
    };
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                <li>
                    <NavLink
                        exact
                        to="/"
                        onClick={() => handleClick("Home Menu")}
                    >
                        {t("Home Menu")}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        exact
                        to={process.env.PUBLIC_URL + "#"}
                        onClick={() => handleClick("Main Menu Tentang")}
                    >
                        {t("Tentang Menu")}
                    </NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                exact
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/about"}
                                onClick={() => handleClick("Sub Menu Tentang")}
                            >
                                {t("Sub menu tentang")}
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                exact
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/gallery"}
                                onClick={() => handleClick("Sub Menu Gallery")}
                            >
                                {t("Galeri Menu")}
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink
                        exact
                        to={process.env.PUBLIC_URL + "/service"}
                        onClick={() => handleClick("Main Menu Layanan")}
                    >
                        {t("Layanan Menu")}
                    </NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                exact
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                                onClick={() => handleClick("Sub Menu Jemput")}
                            >
                                {t("Jemput Menu")}
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                exact
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/2"
                                }
                                onClick={() => handleClick("Sub Menu Antar")}
                            >
                                {t("Antar Menu")}
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink
                        exact
                        to={process.env.PUBLIC_URL + "/blog"}
                        onClick={() => handleClick("Menu Berita")}
                    >
                        {t("Berita Menu")}
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        exact
                        to={process.env.PUBLIC_URL + "/contact"}
                        onClick={() => handleClick("Menu Kontak")}
                    >
                        {t("Kontak Menu")}
                    </NavLink>
                </li>
                <li>
                    <Select
                        className="select-language"
                        options={languageOptions}
                        formatOptionLabel={formatOptionLabel}
                        isSearchable={false}
                        onChange={changeLanguage}
                        value={languageOptions.find(
                            (option) => option.value === i18n.language
                        )}
                    />
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
