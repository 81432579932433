import "./i18n";
import { createRoot } from "react-dom/client";
import App from "./App";
import ReactGA from "react-ga4";

ReactGA.initialize("G-9YQMNX8C0N");

const root = document.getElementById("root");
const reactRoot = createRoot(root);
reactRoot.render(<App />);
