import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";
import { useTranslation } from "react-i18next";

const HomeAboutContainer = () => {
    const { t } = useTranslation();
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={t("SIAPA KAMI?")}
                                title={t("Sang pelopor")}
                            />
                            <span className="date-meta">
                                {t("Berdiri")} 2023 <span className="hr"></span>
                            </span>
                            <p className="mb-5">
                                {t(
                                    "ECOPASIA berkomitmen untuk menjadi pemimpin dalam pengurangan dan daur ulang plastik dan kayu di Jawa Timur."
                                )}
                            </p>
                            <p className="high-light mb-8">
                                {t(
                                    "Kami percaya bahwa melalui inovasi dan pendekatan yang ramah lingkungan, kita dapat menciptakan perubahan nyata dan berkelanjutan."
                                )}
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text={t("Btn Selengkapnya")}
                                path="/about"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/1.webp`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.webp`}
                alt="bg-shape"
            />
        </div>
    );
};

export default HomeAboutContainer;
