import React, { useState, useEffect } from "react";
import BlogItemContainer from "../containers/blog/blog-item";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";

const BlogPage = () => {
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        fetch("https://api-berita-indonesia.vercel.app/suara/bisnis/")
            .then((response) => response.json())
            .then((data) => setArticles(data.data.posts)) // Menggunakan data.data.posts karena artikel berada di dalam properti posts dari respons API
            .catch((error) => console.error("Error:", error));
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <SEO title="Ecopasia – Blog" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={t("Blog Post")}
                        excerpt={
                            t("Berita terbaru dari") + " Suara.com - Bisnis"
                        }
                        image="./images/blog/banner.png"
                    />
                    <BlogItemContainer data={articles} />
                    <NewsletterArea />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default BlogPage;
