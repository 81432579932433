import React from "react";
import PropTypes from "prop-types";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ServiceData from "../data/service.json";
import ServiceDetailsContainer from "../containers/service/service-details";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const ServiceDetails = ({
    match: {
        params: { id },
    },
}) => {
    const serviceId = parseInt(id, 10);
    const data = ServiceData.filter((service) => service.id === serviceId);
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Ecopasia – Service Details" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={data[0]?.title}
                        excerpt="ECOPASIA mengurangi dan daur ulang sampah plastik dan kayu di Jawa Timur. Kami juga mengajarkan tentang lingkungan dan komunitas berkelanjutan. Kami ingin kurangi sampah di darat dan air, dan buat lapangan kerja. Kami pakai inovasi dan pendidikan untuk ubah masa depan jadi lebih hijau."
                        image="/images/service/banner-atas.webp"
                    />
                    <ServiceDetailsContainer data={data[0]} />
                    <NewsletterArea />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

ServiceDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default ServiceDetails;
