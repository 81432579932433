import React, { useState, useEffect } from "react";
import SectionTitleTwo from "../../../components/section-title-two";
import BlogGrid from "../../../components/blog";
import { useTranslation } from "react-i18next";

const HomeBlog = () => {
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        fetch("https://api-berita-indonesia.vercel.app/suara/bisnis/")
            .then((response) => response.json())
            .then((data) => setArticles(data.data.posts)) // Menggunakan data.data karena artikel berada di dalam properti data dari respons API
            .catch((error) => console.error("Error:", error));
    }, []);

    return (
        <div className="blog-section section-pb">
            <div className="container">
                <SectionTitleTwo
                    classOption="title-section mb-lg-10 pb-lg-1"
                    subTitle={t("ARTIKEL BERITA")}
                    title={t(
                        "Berita ter-apdet,<span class='text-primary'> Buat kamu!</span>."
                    )}
                    excerptClassOption="mt-lg-10 pt-lg-3 mb-10 mb-lg-0"
                    excerpt={t(
                        "Baca artikel terbaru dari Suara.com - Bisnis <br /> temukan lebih lanjut untuk #HijauIndonesiaku"
                    )}
                />
                <div className="row mb-n7">
                    {articles &&
                        articles.slice(0, 3).map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-lg-4 col-sm-6 mb-7"
                                >
                                    <BlogGrid
                                        classOption="null"
                                        key={key}
                                        data={single}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default HomeBlog;
