import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { slugify } from "../../utils";
import moment from "moment-timezone"; // import library moment

const BlogGrid = ({ data, classOption }) => {
    // Fungsi untuk memotong judul
    const truncateTitle = (title, length = 63) => {
        return title.length > length
            ? title.substring(0, length - 5) + "....."
            : title;
    };

    // Fungsi untuk mengubah format tanggal
    const formatDate = (date) => {
        return moment(date).tz("Asia/Jakarta").format("D-MMMM-YYYY HH:mm");
    };
    return (
        <div className="blog-card">
            <div className={`thumb bg-light text-center ${classOption}`}>
                <a href={data.link} target="_blank" rel="noopener noreferrer">
                    <img src={data.thumbnail} alt="img" />
                </a>
            </div>
            <div className="blog-content">
                <span className="blog-meta author">Suara.com - Bisnis </span>
                <span className="separator">|</span>
                <span className="blog-meta date">
                    {formatDate(data.pubDate)}
                </span>
                <h3 className="title">
                    <a
                        href={data.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {/* {data.description} */}
                        {truncateTitle(data.description)}
                    </a>
                </h3>
            </div>
        </div>
    );
};

BlogGrid.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string,
};

BlogGrid.defaultProps = {
    classOption: "thumb",
};

export default BlogGrid;
