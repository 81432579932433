import ReactGA from "react-ga4";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const handleClick = () => {
    // Laporkan event ke Google Analytics
    ReactGA.event({ category: "Formulir Kontak", action: "Kirim" });
};

const ContactForm = () => {
    const { t } = useTranslation();
    const { register, errors, handleSubmit, reset } = useForm({
        mode: "onBlur",
    });

    const onSubmit = async (data) => {
        handleClick();
        try {
            const response = await fetch(
                "https://getform.io/f/6b4c028f-4bd1-4ff5-8a94-47f4b74ddb23",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );

            if (response.ok) {
                // Jika respons dari server adalah sukses
                reset(); // Reset form
                window.location.href =
                    "https://getform.io/thank-you?id=6b4c028f-4bd1-4ff5-8a94-47f4b74ddb23";
            } else {
                console.error("Failed to send form");
            }
        } catch (error) {
            console.error("There was an error sending the form", error);
        }
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder={t("Nama Lengkap*")}
                        required
                        ref={register({ required: t("Kenalan dulu yuk....") })}
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={t("Email Kontak*")}
                        required
                        ref={register({
                            required: t("Email nya belum nih..."),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t("email kamu nggak valid nih..."),
                            },
                        })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="message"
                        id="massage"
                        cols="30"
                        rows="10"
                        placeholder={t("Tuliskan pesan disini*")}
                        required
                        ref={register({
                            required: t("Eitss... belum diisi nih"),
                        })}
                    ></textarea>
                    {errors.message && <p>{errors.message.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text={t("Well Done!")}
                    >
                        {t("Kirim Pesan")}
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
