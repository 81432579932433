import React from "react";
import ContactForm from "../../components/contact-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";
import { useTranslation } from "react-i18next";

const ContactContainer = () => {
    const { t } = useTranslation();
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-6 col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">{t("Ikut Kontribusi?")}</h3>
                            <p>
                                {t(
                                    "Hubungi kami untuk meminta penjemputan sampah ke tempat kamu atau minta proposal untuk bekerjasama wujudkan Indonesia hijau."
                                )}
                            </p>
                        </div>

                        <ContactForm />
                    </div>

                    <div className="col-xl-5 col-lg-6 mb-7 offset-xl-1">
                        <div className="contact-address text-center">
                            {ContactData &&
                                ContactData.map((single, key) => {
                                    const translatedSingle = {
                                        ...single,
                                        title: t(single.title),
                                        excerpt: t(single.excerpt),
                                    };
                                    return (
                                        <ContactInfo
                                            key={key}
                                            data={translatedSingle}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactContainer;
