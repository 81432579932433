import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PageTitle = ({ title, excerpt }) => {
    const { t } = useTranslation();
    return (
        <div className="banner-content banner-padding">
            <h3 className="title">{t(title)}</h3>
            <p dangerouslySetInnerHTML={{ __html: t(excerpt) }}></p>
        </div>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
};

export default PageTitle;
