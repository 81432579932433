import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta
                name="description"
                content="Daur Ulang &amp; #LingkunganAmanKitaSemua"
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <script>
                {`
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3698282,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `}
            </script>
            <script>
                {`
        var userId = 3698282 || null; // Replace your_user_id with your own if available.
        window.hj('identify', userId, {
            // Add your own custom attributes here. Some EXAMPLES:
            // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
            // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
            // 'Total purchases': 15, // Send numbers without quotes.
            // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
            // 'Last refund date': null, // Send null when no value exists for a user.
        });
    `}
            </script>
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
};

export default SEO;
