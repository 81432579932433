import React from "react";
import SectionTitle from "../../../components/section-title";
import ServiceBox from "../../../components/service-box";
import ServiceData from "../../../data/service.json";
import { useTranslation } from "react-i18next";

const ServiceListContainer = () => {
    const { t } = useTranslation();
    return (
        <div className="service-section section-pt position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 pb-10 text-center"
                            subTitle={t("Layanan Kami")}
                            title={t(
                                "Lihat <span class='text-primary'>Layanan</span> kami"
                            )}
                            excerptClassOption="null"
                            excerpt={t(
                                "Gerakan daur ulang Ecopasia untuk seluruh masyarakat."
                            )}
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-left">
                            {ServiceData &&
                                ServiceData.slice(0, 1).map((single, key) => {
                                    const translatedSingle = {
                                        ...single,
                                        title: t(single.title),
                                        excerpt: t(single.excerpt),
                                    };
                                    return (
                                        <ServiceBox
                                            data={translatedSingle}
                                            key={key}
                                        />
                                    );
                                })}
                        </div>
                    </div>

                    <div className="col-xl-4 mb-7 order-md-1 order-xl-0">
                        <div className="service-media-img text-center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/service/media.webp`}
                                alt="images_not_found"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-right">
                            {ServiceData &&
                                ServiceData.slice(1, 6).map((single, key) => {
                                    const translatedSingle = {
                                        ...single,
                                        title: t(single.title),
                                        excerpt: t(single.excerpt),
                                    };
                                    return (
                                        <ServiceBox
                                            data={translatedSingle}
                                            key={key}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceListContainer;
